import type {AdminApiSessionProps} from "~/stCommon/api/admin/model/AdminApiSessionProps";
import {StCommonConfig} from "~/stCommon/StCommonConfig";
import type {CookieRef} from "#app";

/**
 * クッキーにセットする管理用のセッション
 */
class MyAdminSession {
  cookieRef: CookieRef<AdminApiSessionProps | null>;
  get email(): string {
    return this.cookieRef.value?.email || '';
  }
  get apiAccessToken(): string {
    return this.cookieRef.value?.apiAccessToken || '';
  }

  constructor(cookieRef: CookieRef<AdminApiSessionProps | null>) {
    this.cookieRef = cookieRef;
  }

  static get cookieName(): string {
    return StCommonConfig.cookies.adminSession.name;
  }

  toString(): string {
    return JSON.stringify({
      email: this.email,
      apiAccessToken: this.apiAccessToken,
    });
  }

  isLoggedIn(): boolean {
    return !!this.email && this.email.length > 0 &&
      !!this.apiAccessToken && this.apiAccessToken.length > 0;
  }

  save(session: AdminApiSessionProps) {
    this.cookieRef.value = session;
  }

  clear() {
    this.cookieRef.value = null;
  }
}

export function useMyAdminSession() {
  const cookieRef = useCookie<AdminApiSessionProps | null>(
    MyAdminSession.cookieName,
    {
      secure: true,
      sameSite: 'strict',
      maxAge: StCommonConfig.cookies.adminSession.maxAge,
    },
  );
  return new MyAdminSession(cookieRef);
}
